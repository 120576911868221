<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="InfoIcon"
              class="mr-75"
            />
            <span>
              Thông tin
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Họ và tên<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Họ và tên"
                  rules="required"
                  :custom-messages="{required: `Họ và tên là bắt buộc`}"
                >
                  <b-form-input
                    v-model="dataInput.fullName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Ngày sinh<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Ngày sinh"
                  rules="required"
                  :custom-messages="{required: `Ngày sinh là bắt buộc`}"
                >
                  <date-time-picker
                    v-model="dataInput.birthDay"
                    :disabledInput="true"
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableProp"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Giới tính</label>
                <div class="d-flex mt-1">
                  <b-form-radio
                    v-model="dataInput.gender"
                    class="mr-1"
                    :value="true"
                  >
                    Nam
                  </b-form-radio>
                  <b-form-radio
                    v-model="dataInput.gender"
                    :value="false"
                  >
                    Nữ
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số CMND/CCCD</label>
                <b-form-input
                  v-model="dataInput.identityCardNumber"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Nơi đăng ký thường trú</label>
                <b-form-input
                  v-model="dataInput.permanentAddress"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Nơi ở hiện tại</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Tỉnh/Thành phố</label>
                <v-select
                  v-model="dataInput.provinceId"
                  :reduce="item => item.id"
                  label="name"
                  :options="provinceList || []"
                  placeholder="Lựa chọn tỉnh/Thành phố"
                  @input="dataDistrict"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Quận/Huyện</label>
                <v-select
                  v-model="dataInput.districtId"
                  :reduce="item => item.id"
                  label="name"
                  :disabled="isDisableDistrict"
                  :options="districtList || []"
                  placeholder="Lựa chọn quận/Huyện"
                  @input="dataWard"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Phường/Xã</label>
                <v-select
                  v-model="dataInput.wardId"
                  :reduce="item => item.id"
                  label="name"
                  :disabled="isDisableWard"
                  :options="wardList || []"
                  placeholder="Lựa chọn phường/xã"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Ngày thu thập<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Ngày sinh"
                  rules="required"
                  :custom-messages="{required: `Ngày sinh là bắt buộc`}"
                >
                  <date-time-picker
                    v-model="dataInput.dateCollect"
                    :disabledInput="true"
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableProp"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="UserIcon"
              class="mr-75"
            />
            <span>
              Đối tượng ưu tiên
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Đối tượng ưu tiên<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Đối tượng ưu tiên"
                  rules="required"
                  :custom-messages="{required: `Đối tượng ưu tiên là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.priorityWorker"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="priorityWorker || []"
                    placeholder="Lựa chọn đối tượng ưu tiên"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="BookOpenIcon"
              class="mr-75"
            />
            <span>
              Trình độ giáo dục
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Trình độ giáo dục phổ thông<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Trình độ giáo dục phổ thông"
                  rules="required"
                  :custom-messages="{required: `Trình độ giáo dục phổ thông là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.educationLevelWorker"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="educationLevelWorker || []"
                    placeholder="Lựa chọn đối tượng ưu tiên"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Trình độ chuyên môn kỹ thuật<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Trình độ chuyên môn kỹ thuật"
                  rules="required"
                  :custom-messages="{required: `Trình độ chuyên môn kỹ thuật là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.qualificationLevel"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="qualificationLevel || []"
                    placeholder="Lựa chọn trình độ chuyên môn kỹ thuật"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Chuyên ngành đào tạo</label>
                <b-form-input
                  v-model="dataInput.specializedTraining"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="DollarSignIcon"
              class="mr-75"
            />
            <span>
              Tình trạng tham gia hoạt động kinh tế
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Tình trạng tham gia hoạt động kinh tế<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Tình trạng tham gia hoạt động kinh tế"
                  rules="required"
                  :custom-messages="{required: `Tình trạng tham gia hoạt động kinh tế là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.statusCollectWorker"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="statusCollectWorker || []"
                    placeholder="Lựa chọn tình trạng tham gia hoạt động kinh tế"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="dataInput.statusCollectWorker === 'NoneWorker'"
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Lý do không tham gia hoạt động kinh tế<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Lý do không tham gia hoạt động kinh tế"
                  rules="required"
                  :custom-messages="{required: `Lý do không tham gia hoạt động kinh tế là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.noneWorkerReason"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="noneWorkerReason || []"
                    placeholder="Lựa chọn lý do không tham gia hoạt động kinh tế"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="BriefcaseIcon"
              class="mr-75"
            />
            <span>
              Người có việc làm
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Vị thế việc làm<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Vị thế việc làm"
                  rules="required"
                  :custom-messages="{required: `Vị thế việc làm là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.jobPosition"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="jobPosition || []"
                    :disabled="dataInput.statusCollectWorker === 'Worker' ? false : true"
                    placeholder="Lựa chọn vị thế việc làm"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Việc làm cụ thể</label>
                <b-form-input
                  v-model="dataInput.job"
                  :disabled="dataInput.statusCollectWorker === 'Worker' ? false : true"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Nơi làm việc</label>
                <b-form-input
                  v-model="dataInput.workPlace"
                  :disabled="dataInput.statusCollectWorker === 'Worker' ? false : true"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="BriefcaseIcon"
              class="mr-75"
            />
            <span>
              Người thất nghiệp
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Tình trạng<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Tình trạng"
                  rules="required"
                  :custom-messages="{required: `Tình trạng là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.unemploymentStatus"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :disabled="dataInput.statusCollectWorker === 'Unemployment' ? false : true"
                    :options="unemploymentStatus || []"
                    placeholder="Lựa chọn tình trạng"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Thời gian thất nghiệp<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Thời gian thất nghiệp"
                  rules="required"
                  :custom-messages="{required: `Thời gian thất nghiệp là bắt buộc`}"
                >
                  <v-select
                    v-model="dataInput.unemploymentTime"
                    :reduce="item => item.key"
                    label="name"
                    :class="{'is-invalid':errors.length > 0 }"
                    :disabled="dataInput.statusCollectWorker === 'Unemployment' ? false : true"
                    :options="unemploymentTime || []"
                    placeholder="Lựa chọn thời gian thất nghiệp"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import axios from 'axios'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import enumSelect from '../EnumSelected'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormRadio,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      dataInput: {
        fullName: '',
        provinceId: 0,
        province: '',
        districtId: 0,
        district: '',
        wardId: 0,
        birthDay: null,
        gender: true,
        identityCardNumber: '',
        permanentAddress: '',
        address: '',
        priorityWorker: 'Disabled',
        ethnic: '',
        educationLevelWorker: 'NonePrimary',
        qualificationLevel: 'None',
        specializedTraining: '',
        statusCollectWorker: 'Worker',
        noneWorkerReason: 'Study',
        jobPosition: 'Owner',
        job: '',
        workPlace: '',
        unemploymentStatus: 'NeverWorker',
        unemploymentTime: 'Under3Month',
        dateCollect: null,
      },
      provinceList: [],
      districtList: [],
      wardList: [],
      isDisableDistrict: true,
      isDisableWard: true,
      disableProp: false,
      priorityWorker: enumSelect.priorityWorker,
      educationLevelWorker: enumSelect.educationLevelWorker,
      qualificationLevel: enumSelect.qualificationLevel,
      statusCollectWorker: enumSelect.statusCollectWorker,
      noneWorkerReason: enumSelect.noneWorkerReason,
      jobPosition: enumSelect.jobPosition,
      unemploymentStatus: enumSelect.unemploymentStatus,
      unemploymentTime: enumSelect.unemploymentTime,
    }
  },
  watch: {
    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          fullName: this.dataDetail.fullName,
          provinceId: this.dataDetail.provinceId,
          province: this.dataDetail.province,
          districtId: this.dataDetail.districtId,
          district: this.dataDetail.district,
          wardId: this.dataDetail.wardId,
          ward: this.dataDetail.ward,
          birthDay: this.dataDetail.birthDay,
          gender: this.dataDetail.gender,
          identityCardNumber: this.dataDetail.identityCardNumber,
          permanentAddress: this.dataDetail.permanentAddress,
          address: this.dataDetail.address,
          priorityWorker: this.dataDetail.priorityWorker,
          ethnic: this.dataDetail.ethnic,
          educationLevelWorker: this.dataDetail.educationLevelWorker,
          qualificationLevel: this.dataDetail.qualificationLevel,
          specializedTraining: this.dataDetail.specializedTraining,
          statusCollectWorker: this.dataDetail.statusCollectWorker,
          noneWorkerReason: this.dataDetail.noneWorkerReason,
          jobPosition: this.dataDetail.jobPosition,
          job: this.dataDetail.job,
          workPlace: this.dataDetail.workPlace,
          unemploymentStatus: this.dataDetail.unemploymentStatus,
          unemploymentTime: this.dataDetail.unemploymentTime,
          dateCollect: this.dataDetail.dateCollect,
        }
      }
      await this.fetchComboboxDistrict(this.dataDetail.provinceId)
      await this.fetchComboboxWard(this.dataDetail.districtId)
      this.isDisableDistrict = false
      this.isDisableWard = false
    },
  },

  async created() {
    await this.fetchComboboProvince()
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    // Tỉnh thành phố
    async fetchComboboProvince() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CITY)
      this.provinceList = data
    },

    // Quận huyện
    async fetchComboboxDistrict(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_DISTRICT}${a}`)
      this.districtList = data
    },

    // Xã phường
    async fetchComboboxWard(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_WARD}${a}`)
      this.wardList = data
    },

    async dataDistrict(val) {
      if (this.provinceList.length > 0 && this.dataInput.provinceId !== null) {
        this.isDisableDistrict = false
        this.dataInput.districtId = ''
        this.dataInput.wardId = ''
        await this.fetchComboboxDistrict(this.dataInput.provinceId)
      }
      if (!this.dataInput.provinceId) {
        this.dataInput.districtId = ''
        this.dataInput.wardId = ''
        this.isDisableDistrict = true
        this.isDisableWard = true
      }
    },

    async dataWard(val) {
      if (this.districtList.length > 0 && this.dataInput.districtId !== null) {
        this.isDisableWard = false
        this.dataInput.wardId = ''
        await this.fetchComboboxWard(this.dataInput.districtId)
      }
      if (!this.dataInput.districtId) {
        this.dataInput.wardId = ''
        this.isDisableWard = true
      }
    },

    resetData() {
      this.dataInput = {
        fullName: '',
        provinceId: 0,
        province: '',
        districtId: 0,
        district: '',
        wardId: 0,
        birthDay: null,
        gender: true,
        identityCardNumber: '',
        permanentAddress: '',
        address: '',
        priorityWorker: 'Disabled',
        ethnic: '',
        educationLevelWorker: 'NonePrimary',
        qualificationLevel: 'None',
        specializedTraining: '',
        statusCollectWorker: 'Worker',
        noneWorkerReason: 'Study',
        jobPosition: 'Owner',
        job: '',
        workPlace: '',
        unemploymentStatus: 'NeverWorker',
        unemploymentTime: 'Under3Month',
        dateCollect: null,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
