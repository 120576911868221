export default {
  // Enum đối tượng ưu tiên
  priorityWorker: [
    {
      name: 'Người khuyết tật',
      key: 'Disabled',
    },
    {
      name: 'Thuộc hộ nghèo, cận nghèo',
      key: 'Poor',
    },
    {
      name: 'Dân tộc thiểu số',
      key: 'Ethnic',
    },
  ],
  // Enum Trình độ giáo dục phổ thông
  educationLevelWorker: [
    {
      name: 'Chưa học xong tiểu học',
      key: 'NonePrimary',
    },
    {
      name: 'Tốt nghiệp tiểu học',
      key: 'Primary',
    },
    {
      name: 'Tốt nghiệp trung học cơ sở',
      key: 'Junior',
    },
    {
      name: 'Tốt nghiệp trung học phổ thông',
      key: 'High',
    },
  ],
  // Enum trình độ chuyên môn
  qualificationLevel: [
    {
      name: 'Chưa qua đào tạo',
      key: 'None',
    },
    {
      name: 'CNKT không có bằng',
      key: 'NoneDegree',
    },
    {
      name: 'Chứng chỉ nghề dưới 3 tháng',
      key: 'DegreeUnder3Month',
    },
    {
      name: 'Sơ cấp',
      key: 'Primary',
    },
    {
      name: 'Trung cấp',
      key: 'Intermediate',
    },
    {
      name: 'Cao đẳng',
      key: 'College',
    },
    {
      name: 'Đại học',
      key: 'University',
    },
    {
      name: 'Trên đại học',
      key: 'Graduate',
    },
  ],
  // Enum tình trạng tham gia hoạt động kinh tế
  statusCollectWorker: [
    {
      name: 'Người có việc làm',
      key: 'Worker',
    },
    {
      name: 'Người thất nghiệp',
      key: 'Unemployment',
    },
    {
      name: 'Không tham gia hoạt động kinh tế',
      key: 'NoneWorker',
    },
  ],
  // Enum lý do không tham gia hoạt động kinh tế
  noneWorkerReason: [
    {
      name: 'Đi học',
      key: 'Study',
    },
    {
      name: 'Hưu trí',
      key: 'Retire',
    },
    {
      name: 'Nội trợ',
      key: 'Housework',
    },
    {
      name: 'Khuyết tật',
      key: 'Disabled',
    },
    {
      name: 'Khác',
      key: 'Another',
    },
  ],
  // Enum vị thế việc làm
  jobPosition: [
    {
      name: 'Chủ cơ sở SXKD',
      key: 'Owner',
    },
    {
      name: 'Tự làm',
      key: 'Self',
    },
    {
      name: 'Lao động gia đình',
      key: 'FamilyWorker',
    },
    {
      name: 'Làm công ăn lương',
      key: 'Worker',
    },
  ],
  // Enum trạng thái thất nghiệp
  unemploymentStatus: [
    {
      name: 'Chưa bao giờ làm việc',
      key: 'NeverWorker',
    },
    {
      name: 'Đã từng làm việc',
      key: 'UsedTo',
    },
  ],
  // Enum thời gian thất nghiệp
  unemploymentTime: [
    {
      name: 'Dưới 3 tháng',
      key: 'Under3Month',
    },
    {
      name: 'Từ 3 tháng đến 1 năm',
      key: 'From3MonthTo1Year',
    },
    {
      name: 'Trên 1 năm',
      key: 'Above1Year',
    },
  ],
}
